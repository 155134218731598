import { faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ContactCard from "../../components/contactcard/ContactCard";
import Col from "../../components/container/Col";
import Container from "../../components/container/Container";
import Row from "../../components/container/Row";
import Section from "../../components/section/Section";
import SectionHeader from "../../components/sectionheader/SectionHeader";

export default function ContactContainer() {
  return (
    <Section id={"contact"}>
      <SectionHeader title={"Contact me"} />
      <Container>
        <Row>
          <Col span={4}>
            <ContactCard
              icon={faDiscord}
              buttonText={"Discord"}
              buttonUrl={"https://discord.cayeoficial.com"}
              buttonColor={"#5865F2"}
            />
          </Col>
          <Col span={4}>
            <ContactCard
              icon={faEnvelope}
              buttonText={"Email"}
              buttonUrl={"mailto:caye@cayeoficial.com"}
              buttonColor={"#D44638"}
            />
          </Col>
          <Col span={4}>
            <ContactCard
              icon={faGithub}
              buttonText={"GitHub"}
              buttonUrl={"https://github.com/CayeOficial"}
              buttonColor={"#24292E"}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}