import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactCard.scss";

export default function ContactCard({ icon, buttonText, buttonUrl, buttonColor }) {
  return (
    <div className="card-container">
      <FontAwesomeIcon icon={icon} size="4x" />
      <a href={buttonUrl} style={{ backgroundColor: buttonColor }}>{buttonText.toUpperCase()}</a>
    </div>
  );
}