import React from "react";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function Header({ navItems }) {
  return (
    <div className="header-container">
      <header className="header">
        <a href="/" className="text-logo">
          CAYEOFICIAL
          <FontAwesomeIcon icon={faCheckCircle} className="badge" />
        </a>
        <nav>
          <ul className="nav-list">
            {navItems.map(({ href, displayName }) => (
              <li key={href} className="nav-item">
                <a href={href}>{displayName}</a>
              </li>
            ))}
          </ul>
        </nav>
      </header>
    </div>
  );
}