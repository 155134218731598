import Section from "../../components/section/Section";
import SectionHeader from "../../components/sectionheader/SectionHeader";
import "./Skills.scss"

export default function SkillsContainer() {
  return (
    <Section id={"skills"}>
      <SectionHeader
        title={"Skills"}
        description={
          "Here are some of the platforms and languages I work with"
        }
      />
      <div className="image-container">
        <img
          alt="skills"
          className="image-center"
          src="https://skillicons.dev/icons?i=nodejs,java,py,css,html,sass,js,bootstrap,jquery,mysql,sequelize,sqlite,redis,react,ts,git,linux,bash,raspberrypi,arduino,cloudflare,laravel,codepen,vscode,discord,autocad,github,docker,gcp,gradle,maven,idea,jenkins,nginx,php,azure&perline=9"></img>
      </div>
    </Section>
  );
}