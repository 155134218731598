import AppleEmoji from "../../components/emoji/AppleEmoji";
import DiscordMention from "../../components/mention/DiscordMention";
import Section from "../../components/section/Section";
import TextCard from "../../components/textcard/TextCard";

export default function GreetingContainer() {
  return (
    <Section id={""}>
      <TextCard
        title={
          <>
            Hi, <AppleEmoji name={"wave"} width={60} />
            <br />
            I'm CayeOficial
          </>
        }
        description={
          <>
            Hey <DiscordMention username={"everyone"} />! I'm a self-trained
            full-stack developer <br />
            with almost 3 years of experience behind me, reflected in <br />
            all the projects I have done throughout my trajectory as a
            developer.
            <br />
            <br />I learn proactively and am always updating my knowledge.
            <br />
            More and more people and networks trust me and receive quality
            work.
            <br />
            <br />
            Do you need a developer for some of your work? Keep scrolling
            down to
            <br />
            see what platforms I can handle!{" "}
            <AppleEmoji name={"arrow-down"} width={25} />
          </>
        }
        image={"/img/avatar.png"}
      />
    </Section>
  );
}