import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import emojiData from "./emojis.json";
import { EmojiProvider } from "react-apple-emojis";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <EmojiProvider data={emojiData}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </EmojiProvider>
);
