import ImageCarousel from "../../components/carousel/ImageCarousel";
import Section from "../../components/section/Section";
import SectionHeader from "../../components/sectionheader/SectionHeader";

export default function ClientsContainer() {
  return (
    <Section id={"clients"}>
      <SectionHeader
        title={"My Clients"}
        description={"See big networks I have worked with"}
      />
      <ImageCarousel
        images={[
          {
            legend: "HorusMC",
            src: "/img/clients/horusmc.png",
          },
          {
            legend: "MineLatino",
            src: "/img/clients/minelatino.png",
          },
          {
            legend: "VirtualHit",
            src: "/img/clients/virtualhit.png",
          },
          {
            legend: "Voltyum",
            src: "/img/clients/voltyum.png",
          },
          {
            legend: "Blurkit",
            src: "/img/clients/blurkit.png",
          },
          {
            legend: "StelarMC",
            src: "/img/clients/stelarmc.png",
          },
          {
            legend: "ZapHosting",
            url: "https://zap-hosting.com",
            src: "/img/clients/zaphosting.png",
          },
          {
            legend: "MineLibre",
            src: "/img/clients/minelibre.png",
          },
          {
            legend: "RevengeNetwork",
            src: "/img/clients/revengenetwork.png",
          },
          {
            legend: "NZCraft",
            src: "/img/clients/nzcraft.png",
          },
          {
            legend: "DeluxeZone",
            src: "/img/clients/deluxezone.png",
          },
          {
            legend: "VoxCraft",
            src: "/img/clients/voxcraft.png",
          },
          {
            legend: "SuperDou",
            src: "/img/clients/superdou.png",
          },
          {
            legend: "HaliaCraft",
            src: "/img/clients/haliacraft.png",
          },
          {
            legend: "Americraft",
            src: "/img/clients/americraft.png",
          },
          {
            legend: "UranoCraft",
            src: "/img/clients/uranocraft.png",
          },
          {
            legend: "Taloreros",
            src: "/img/clients/taloreros.png",
          },
          {
            legend: "Obsilion",
            src: "/img/clients/obsilion.png",
          },
          /*{
            legend: "Hybox",
            src: "/img/clients/hybox.png",
          },*/
          {
            legend: "HyperMine",
            src: "/img/clients/hypermine.png",
          },
          {
            legend: "Minefun",
            src: "/img/clients/minefun.png",
          },
          {
            legend: "Vulturehub",
            src: "/img/clients/vulturehub.png",
          },
        ]}
      />
    </Section>
  );
}