//import logo from './logo.svg';
import ClientsContainer from './containers/clients/Clients';
import ContactContainer from './containers/contact/Contact';
import Footer from './containers/footer/Footer';
import GreetingContainer from './containers/greeting/Greeting';
import HabilitiesContainer from './containers/habilities/Habilities';
import HeaderContainer from './containers/header/Header';
import SkillsContainer from './containers/skills/Skills';

function App() {
  return (
    <div className="App">
        <HeaderContainer />
        <GreetingContainer />
        <SkillsContainer />
        <HabilitiesContainer />
        <ClientsContainer />
        <ContactContainer />
        <Footer />
    </div>
  );
}

export default App;
