import Header from "../../components/header/Header";

export default function HeaderContainer() {
  return (
    <Header
      navItems={[
        { href: "#", displayName: "Home" },
        { href: "#skills", displayName: "Skills" },
        { href: "#habilities", displayName: "Habilities" },
        { href: "#clients", displayName: "Clients" },
        { href: "#contact", displayName: "Contact" },
      ]}
    />
  );
}