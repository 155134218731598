import { Carousel } from "react-responsive-carousel";
import "./ImageCarousel.scss";

export default function ImageCarousel({ images }) {
  return (
    <Carousel autoPlay infiniteLoop useKeyboardArrows interval={2000}>
      {images.map(({ legend, url, src }) => (
        <div className="image-wrapper" key={legend}>
          <img
            src={src}
            alt={legend}
            onClick={() => {
              if (url) {
                window.open(url, "_blank", "noopener,noreferrer");
              }
            }}
          />
        </div>
      ))}
    </Carousel>
  );
}