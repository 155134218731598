import AppleEmoji from "../emoji/AppleEmoji"
import "./Habilities.scss";

export default function Hability({ text }) {
  return (
    <div className="hability-container">
      <AppleEmoji name={"zap"} width={20} />
      <p className="description text">{text}</p>
    </div>
  );
}