import "./TextCard.scss";

export default function TextCard({ title, description, image }) {
  return (
    <div className="textcard">
      <div className="text-container">
        <h1 className="title">{title}</h1>
        <p className="description">{description}</p>
      </div>
      {image && <img className="textcard-image" alt="" src={image}></img>}
    </div>
  );
}